<template>
<div class="team">
	<div class="container mt-5">
		<div class="row my-5">
			<h1 class="mt-5">Meet The Team</h1>
			<h5>We've got a well-seasoned team made up of the best and brightest innovators.</h5>
		</div>
		<div class="row">
			<div class="team-member col-sm-6 col-md-4 text-center my-5" v-for="(staff, key) in team" :key="key">
				<img class="img-fluid img-staff" alt="" :src="staff.image">
				<h3 class="mt-4">{{staff.name}}</h3>
				<h5>{{staff.title}}</h5>
				<div class="row mt-4">
					<div class="col-4 offset-4">
						<a v-if="staff.twitter !== ''" class="px-2" target="_blank" :href="staff.twitter">
							<div class="d-inline-flex social-link"><img alt="" src="@/assets/images/icon-twitter.png"></div>
						</a>
						<a v-if="staff.linkedin !== ''" class="px-2" target="_blank" :href="staff.linkedin">
							<div class="d-inline-flex social-link"><img alt="" class="ms-nudge" src="@/assets/images/linkedin.svg" width="20"></div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Footer></Footer>
</div>
</template>

<script>
import Footer from "../components/Footer";
export default {
	name: "Team",
	components: {Footer},
	data() {
		return {
			team: [
				{name: "Scott Hague", title: "CEO, AlphaBatem Labs", twitter: "https://twitter.com/CloakdDev", linkedin: "https://www.linkedin.com/in/scott-hague/", image: "/images/team_photos/Scott_Hague.png"},
				{name: "John McLeod", title: "COO, AlphaBatem Labs", twitter: "https://twitter.com/decentralizedaf", linkedin: "https://www.linkedin.com/in/johndmcleod/", image: "/images/team_photos/John_Mcleod.png"},
				{name: "Nicholas Harrison", title: "CMO, AlphaBatem Labs", twitter: "https://twitter.com/Nickishweb3", linkedin: "https://www.linkedin.com/in/nicholas-harrison-b61497224/", image: "/images/team_photos/Nick_Harrison.png"},
				{name: "Francis So", title: "CFO, AlphaBatem Labs", twitter: "https://twitter.com/_frankyS_", linkedin: "https://www.linkedin.com/in/francis-s-4a09301/", image: "/images/team_photos/Francis_so.png"},
				{name: "Travis C.", title: "Art Director", twitter: "https://twitter.com/lazy_diego", linkedin: "https://www.linkedin.com/in/travis-c-90123266/", image: "/images/team_photos/Travis_C.png"},
				{name: "Amman Istwal", title: "Community Manager", twitter: "https://twitter.com/aciddun", linkedin: "https://www.linkedin.com/in/amanistwal/", image: "/images/team_photos/Amman_Istwal.png"},
				{name: "Ehigie Akala", title: "Social Media Manager", twitter: "", linkedin: "https://www.linkedin.com/in/ehigie-akala-826407156/", image: "/images/team_photos/Ehigie_Akala.png"},
				{name: "Lois Nwanze", title: "Lead UX/UI Designer", twitter: "", linkedin: "https://www.linkedin.com/in/felicitynwanze/", image: "/images/team_photos/Lois_Nwanze.png"},
				{name: "Temitope Olatunji", title: "Python Developer", twitter: "", linkedin: "https://www.linkedin.com/in/temitope-olatunji/", image: "/images/team_photos/Temitope_Olatunji.png"}
			]
		}
	}
}
</script>

<style scoped>
.img-staff {
	max-width: 80%;
}

.img-staff,h3 {
	transition: all 0.3s ease;
}

.team-member:hover .img-staff {
	transform: scale(1.1);
}

.team-member:hover h3 {
	color: var(--link-primary);
}

.social-link {
	background: white;
	border-radius: 66%;
	padding: 5px;
	width: 36px;
	height: 36px;
}

.social-link:hover {
	background: var(--link-primary);
}

.social-link:hover img {
	filter: invert(1);
}


.ms-nudge {
	margin-left: 3px;
}
</style>